<template>
  <BaseModal
    v-model="model"
    :title="$t('Nuevo Adjunto')"
    size="lg"
    :loading="loading"
    static-modal
    @confirm-button-click="handleConfirmButtonClick"
  >
    <DocumentForm ref="document-form" v-model="formModel" :openModal="model" :view="view" />
  </BaseModal>
</template>

<script>
import { mapActions } from "vuex";
import BaseModal from "@/components/ui/modal/BaseModal.vue";
import DocumentForm from "@/components/documents/form/DocumentForm.vue";

export default {
  name: "DocumentCreationModal",
  components: { DocumentForm, BaseModal },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    parameters: {
      type: Object,
      default: () => {},
    },
    view: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      formModel: {},
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    documentForm() {
      return this.$refs["document-form"];
    },
  },
  methods: {
    ...mapActions("documents", ["createDocument"]),
    async handleConfirmButtonClick() {
      const valid = await this.documentForm.handleSubmit()
      if (!valid) {
        return;
      }
      
      const file = this.documentForm.getDocument();
      const document = {
        ...this.formModel,
        ...this.parameters,
        doc: file,
        name: file.name,
        type: file.name.split(".").pop(),
      };
      const formData = new FormData();
      Object.keys(document).forEach((key) =>
      formData.append(key, document[key])
      );
      
      formData.append("category_id", document.category.id);
      
      this.loading = true;
      try {
        await this.createDocument(formData);
        this.$emit("input", false);
        this.$emit("document-created");
      } finally {
        this.loading = false;
        this.documentForm.clearForm();
      }
    },
  },
};
</script>
