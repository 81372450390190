<template>
  <b-modal
    v-model="model"
    :title="title"
    :size="size"
    centered
    :scrollable="scrollable"
    :lazy="lazy"
    :static="staticModal"
  >
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      rounded="sm"
    >
      <slot />
    </b-overlay>
    <template #modal-footer>
      <b-row align-h="end">
        <b-col cols="auto">
          <a
            class="btn btn-outline-danger mr-1 text-nowrap rounded-sm text-danger text-decoration-none"
            @click="$emit('input', false)"
          >
            {{ cancelButtonText || $t('Cancelar') }}
          </a>
          <b-button
            variant="primary"
            class="text-nowrap rounded-sm"
            @click="$emit('confirm-button-click')"
          >
            {{ confirmButtonText || $t('Guardar') }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    confirmButtonText: {
      type: String,
      default: null,
    },
    cancelButtonText: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'xl',
    },
    title: {
      type: String,
      default: '',
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    staticModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style scoped>
</style>
