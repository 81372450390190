<template>
  <validation-observer ref="documentForm">
    <b-form @submit.prevent="handleSubmit">
      <b-row>
        <b-col cols="12">
          <b-form-group :label="$t('Documento')" label-for="document">
            <ImageDropzone
              id="document"
              ref="document-drop-zone"
              max-files="1"
            />
            <small v-if="dropZoneError" class="text-danger">
              {{ dropZoneError }}
            </small>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group :label="$t('Categoria')" label-for="documentCategory">
            <validation-provider
              #default="{ errors }"
              :name="$t('Categoria')"
              rules="required"
            >
              <v-select
                v-model="model.category"
                label="name"
                :filterable="false"
                :searchable="false"
                :options="listCategories"
                :placeholder="$t('Categoria')"
                :disabled="disabledSelectCategories"
                @input="update('category', $event)"
              />
              <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            :label="$t('Descripcion')"
            label-for="document-description"
          >
            <b-form-textarea
              id="document-description"
              :value="model.description"
              :placeholder="$t('Descripcion')"
              rows="4"
              max-rows="4"
              @input="update('description', $event)"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import FormMixin from "@/mixins/formMixin";
import Ripple from 'vue-ripple-directive'
import ImageDropzone from "@/components/elements/ImageDropzone/ImageDropzone.vue";
import { BRow, BCol, BFormGroup, BForm } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { mapActions, mapGetters } from "vuex";
import vSelect from "vue-select";

export default {
  name: "DocumentForm",
  props: {
    openModal: {
      default: false
    },
    view: {
      type: String,
    },
  },
  components: { 
    ImageDropzone, 
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BRow, 
    BCol,
    BFormGroup,
    BForm,
  },
  directives: {
    Ripple,
  },
  mixins: [FormMixin],
  data() {
    return {
      dropZoneError: null,
      required,
    };
  },
  computed: {
    ...mapGetters({
      selectCategories: "docCategories/getDocCategories",
      currentUser: 'auth/getUser',
    }),
    documentDropZone() {
      return this.$refs["document-drop-zone"];
    },
    disabledSelectCategories () {
      return ['tec_client', 'admin'].includes(this.currentUser.roles[0].name)
    },
    listCategories () {
      switch (this.view) {
        case 'projects':
          return this.selectCategories.filter(({ name }) => name != 'Comerciales');
        case 'products':
          return this.selectCategories.filter(({ name }) => !['Administrativos','Mantenimiento'].includes(name));
        default:
          return this.selectCategories || [];
      }
    }
  },
  watch: {
    openModal (value) {
      if (value && ['admin','tec_client'].includes(this.currentUser.roles[0].name)) {
        this.update('category', this.selectCategories.find(({ id }) => id === 4)) 
      }
    }
  },
  methods: {
    ...mapActions({
      listDocCategories: "docCategories/listDocCategories",
    }),
    async validate() {
      return !this.isDropZoneEmpty();
    },
    getDocument() {
      return this.isDropZoneEmpty()
        ? null
        : this.documentDropZone.getFiles().pop();
    },
    isDropZoneEmpty() {
      const files = this.documentDropZone.getFiles();
      const validation = !files || files.length === 0;
      this.dropZoneError = validation
        ? this.$t("Debe incluir almenos 1 archivo")
        : null;

      return validation;
    },
    clearForm() {
      this.documentDropZone.emptyDropzone();
      this.$emit("input", {});
    },
    async handleSubmit() {
      return this.$refs.documentForm.validate() && this.validate()
    },
  },
  async created() {
    await this.listDocCategories({
      parent: 0,
      per_page: 0,
      page: 0,
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
</style>
