<template>
  <b-overlay
    :show="loading"
    variant="white"
    spinner-variant="primary"
    rounded="sm"
  >
    <b-row
      align-h="end"
      class="pb-1 pt-2 px-2"
    >
      <b-col cols="auto">
        <b-button v-if="uploadPermissions" @click="modalVisible = true">
          {{ $t('Adjuntar documento') }}
        </b-button>
      </b-col>
    </b-row>
    <!-- Files tables -->
    <b-row class="p-2">
      <!-- Administrativo -->
      <b-col
          v-if="showAdmin"
          cols="12"
          lg="4"
      >
        <div class="d-flex flex-column justify-content-between h-100">
          <div>
            <img
                v-if="administrativeData"
                class="w-100 img-fluid"
                :alt="administrativeData.name"
                :src="administrativeData.image.length ? administrativeData.image[0].path : ''"
            >
            <h3 class="my-1 text-center text-primary">
              {{ administrativeData.name }}
            </h3>
            <div>
              <b-table-simple
                  hover
                  class="table-sm"
              >
                <thead
                    role="rowgroup"
                    class=""
                >
                <tr
                    role="row"
                    class=""
                >
                  <th
                      role="columnheader"
                      scope="col"
                      aria-colindex="1"
                      class=""
                  >
                    <div>{{ $t('Nombre') }}</div>
                  </th>
                  <th
                      role="columnheader"
                      scope="col"
                      aria-colindex="2"
                      class=""
                  >
                    <div>{{ $t('Tipo') }}</div>
                  </th>
                  <th
                      role="columnheader"
                      scope="col"
                      aria-colindex="3"
                      class="text-center w-30"
                  >
                    <div>{{ $t('Acciones') }}</div>
                  </th>
                </tr>
                </thead>
                <draggable
                    :list="administrativeDocs"
                    :group="{ name: 'administrativeDocs', pull: 'clone', put: false }"
                    tag="tbody"
                    :sort="userSuperAdmin"
                    @change="handleChangeOrder4"
                >

                  <b-tr
                      v-for="(item, index) in administrativeDocs"
                      :key="index"
                  >
                    <b-td>
                      {{ item.description }}
                    </b-td>
                    <b-td>
                      {{ item.type }}
                    </b-td>
                    <b-td class="text-center">
                      <span>
                        <feather-icon
                            icon="DownloadIcon"
                            size="20"
                            class="text-primary"
                            @click="download(item)"
                        />
                        <span
                            v-if="userSuperAdmin ||
                            currentUser.roles[0].name === 'tec_client' ||
                            currentUser.roles[0].name === 'admin'
                          "
                            class="d-inline-block pl-1 text-primary"
                            @click="handleEditIconClick(item)"
                        >
                          <feather-icon
                              icon="EditIcon"
                              size="20"
                          />
                        </span>
                        <span
                            v-if="userSuperAdmin ||
                            currentUser.roles[0].name === 'tec_client' ||
                            currentUser.roles[0].name === 'admin'
                          "
                            class="d-inline-block pl-1 text-danger"
                            @click="handleDeleteIconClick(item)"
                        >
                          <feather-icon
                              icon="TrashIcon"
                              size="20"
                          />
                        </span>
                      </span>
                    </b-td>
                  </b-tr>
                </draggable>
              </b-table-simple>
            </div>
          </div>
        </div>
      </b-col>
      <!-- Comercial -->
      <b-col
        v-if="showCom && currentUser.roles[0].name !== 'tec_client'"
        cols="12"
        lg="4"
      >
        <div class="d-flex flex-column justify-content-between h-100">
          <div>
            <img
              v-if="showComercialData"
              class="w-100 img-fluid"
              :alt="comercialData.name"
              :src="comercialData.image[0].path"
            >
            <h3 class="my-1 text-center text-primary">
              {{ comercialData.name }}
            </h3>
            <div v-if="userSuperAdmin">
              <!-- draggable -->
              <b-table-simple
                hover
                class="table-sm"
              >
                <thead
                    role="rowgroup"
                    class=""
                >
                <tr
                    role="row"
                    class=""
                >
                  <th
                      role="columnheader"
                      scope="col"
                      aria-colindex="1"
                      class=""
                  >
                    <div>{{ $t('Nombre') }}</div>
                  </th>
                  <th
                      role="columnheader"
                      scope="col"
                      aria-colindex="2"
                      class=""
                  >
                    <div>{{ $t('Tipo') }}</div>
                  </th>
                  <th
                      role="columnheader"
                      scope="col"
                      aria-colindex="3"
                      class="text-center w-30"
                  >
                    <div>{{ $t('Acciones') }}</div>
                  </th>
                </tr>
                </thead>
                <draggable
                  :list="comercialDocs"
                  :group="{ name: 'comercials', pull: 'clone', put: false }"
                  tag="tbody"
                  @change="handleChangeOrder"
                >
                  <b-tr
                    v-for="(item, index) in comercialDocs"
                    :key="index"
                  >
                    <b-td>
                      {{ item.description }}
                    </b-td>
                    <b-td>
                      {{ item.type }}
                    </b-td>
                    <b-td class="text-center">
                      <span>

                        <feather-icon
                          icon="DownloadIcon"
                          size="20"
                          class="text-primary"
                          @click="download(item)"
                        />

                        <span
                          v-if="userSuperAdmin"
                          class="d-inline-block pl-1 text-primary"
                          @click="handleEditIconClick(item)"
                        >
                          <feather-icon
                            icon="EditIcon"
                            size="20"
                          />
                        </span>
                        <span
                          v-if="userSuperAdmin"
                          class="d-inline-block pl-1 text-danger"
                          @click="handleDeleteIconClick(item)"
                        >
                          <feather-icon
                            icon="TrashIcon"
                            size="20"
                          />
                        </span>
                      </span>
                    </b-td>
                  </b-tr>
                </draggable>
              </b-table-simple>
            </div>
            <div v-else>
              <!-- tabla -->
              <b-table
                hover
                class="table-sm"
                :items="comercialDocs"
                :fields="fields"
              >
                <template #cell(actions)="{ item }">

                  <feather-icon
                    icon="DownloadIcon"
                    size="20"
                    class="text-primary"
                    @click="download(item)"
                  />
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </b-col>
      <!-- Tecnicos -->
      <b-col
        v-if="showTech"
        cols="12"
        lg="4"
      >
        <div class="d-flex flex-column justify-content-between h-100">
          <div>
            <img
              v-if="showComercialData"
              class="w-100 img-fluid"
              :alt="techData.name"
              :src="techData.image[0].path"
            >
            <h3 class="my-1 text-center text-primary">
              {{ techData.name }}
            </h3>
            <div v-if="userSuperAdmin">
              <!-- dragable -->
              <b-table-simple
                hover
                class="table-sm"
              >
                <thead
                  role="rowgroup"
                  class=""
                >
                  <tr
                    role="row"
                    class=""
                  >
                    <th
                      role="columnheader"
                      scope="col"
                      aria-colindex="1"
                      class=""
                    >
                      <div>{{ $t('Nombre') }}</div>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      aria-colindex="2"
                      class=""
                    >
                      <div>{{ $t('Tipo') }}</div>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      aria-colindex="3"
                      class="text-center w-30"
                    >
                      <div>{{ $t('Acciones') }}</div>
                    </th>
                  </tr>
                </thead>
                <draggable
                  :list="techDocs"
                  :group="{ name: 'tech', pull: 'clone', put: false }"
                  tag="tbody"
                  @change="handleChangeOrder2"
                >
                  <b-tr
                    v-for="(item, index) in techDocs"
                    :key="index"
                  >
                    <b-td>
                      {{ item.description }}
                    </b-td>
                    <b-td>
                      {{ item.type }}
                    </b-td>
                    <b-td class="text-center">
                      <span>
                        <feather-icon
                          icon="DownloadIcon"
                          size="20"
                          class="text-primary"
                          @click="download(item)"
                        />
                        <span
                          v-if="userSuperAdmin"
                          class="d-inline-block pl-1 text-primary"
                          @click="handleEditIconClick(item)"
                        >
                          <feather-icon
                            icon="EditIcon"
                            size="20"
                          />
                        </span>
                        <span
                          v-if="userSuperAdmin"
                          class="d-inline-block pl-1 text-danger"
                          @click="handleDeleteIconClick(item)"
                        >
                          <feather-icon
                            icon="TrashIcon"
                            size="20"
                          />
                        </span>
                      </span>
                    </b-td>
                  </b-tr>
                </draggable>
              </b-table-simple>
            </div>
            <div v-else>
              <!-- tabla -->
              <b-table
                hover
                class="table-sm"
                :items="techDocs"
                :fields="fields"
              >
                <template #cell(actions)="{ item }">
                  <div class="text-center">
                    <feather-icon
                      icon="DownloadIcon"
                      size="20"
                      class="text-primary"
                      @click="download(item)"
                    />
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </b-col>
      <!-- Manuales -->
      <b-col
        v-if="showMan"
        cols="12"
        lg="4"
      >
        <div class="d-flex flex-column justify-content-between h-100">
          <div>
            <img
              v-if="showComercialData"
              class="w-100 img-fluid"
              :alt="manualData.name"
              :src="manualData.image[0].path"
            >
            <h3 class="my-1 text-center text-primary">
              {{ manualData.name }}
            </h3>
            <div v-if="userSuperAdmin">
              <!-- dragable -->
              <b-table-simple
                hover
                class="table-sm"
              >
                <thead
                  role="rowgroup"
                  class=""
                >
                  <tr
                    role="row"
                    class=""
                  >
                    <th
                      role="columnheader"
                      scope="col"
                      aria-colindex="1"
                      class=""
                    >
                      <div>{{ $t('Nombre') }}</div>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      aria-colindex="2"
                      class=""
                    >
                      <div>{{ $t('Tipo') }}</div>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      aria-colindex="3"
                      class="text-center w-30"
                    >
                      <div>{{ $t('Acciones') }}</div>
                    </th>
                  </tr>
                </thead>
                <draggable
                  :list="documents"
                  :group="{ name: 'documents', pull: 'clone', put: false }"
                  tag="tbody"
                  @change="handleChangeOrder3"
                >
                  <b-tr
                    v-for="(item, index) in documents"
                    :key="index"
                  >
                    <b-td>
                      {{ item.description }}
                    </b-td>
                    <b-td>
                      {{ item.type }}
                    </b-td>
                    <b-td class="text-center">
                      <span>

                        <feather-icon
                          icon="DownloadIcon"
                          size="20"
                          class="text-primary"
                          @click="download(item)"
                        />

                        <span
                          v-if="userSuperAdmin"
                          class="d-inline-block pl-1 text-primary"
                          @click="handleEditIconClick(item)"
                        >
                          <feather-icon
                            icon="EditIcon"
                            size="20"
                          />
                        </span>
                        <span
                          v-if="userSuperAdmin"
                          class="d-inline-block pl-1 text-danger"
                          @click="handleDeleteIconClick(item)"
                        >
                          <feather-icon
                            icon="TrashIcon"
                            size="20"
                          />
                        </span>
                      </span>
                    </b-td>
                  </b-tr>
                </draggable>
              </b-table-simple>
            </div>
            <div v-else>
              <!-- tabla -->
              <b-table
                hover
                class="table-sm"
                :items="documents"
                :fields="fields"
              >
                <template #cell(actions)="{ item }">

                  <feather-icon
                    icon="DownloadIcon"
                    size="20"
                    class="text-primary"
                    @click="download(item)"
                  />
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </b-col>
      <!-- Mantenimiento -->
      <b-col
        v-if="showMain"
        cols="12"
        lg="4"
      >
        <div class="d-flex flex-column justify-content-between h-100">
          <div>
            <img
              v-if="maintenanceData"
              class="w-100 img-fluid"
              :alt="maintenanceData.name"
              :src="maintenanceData.image.length ? maintenanceData.image[0].path : ''"
              >
            <h3 class="my-1 text-center text-primary">
              {{ maintenanceData.name }}
            </h3>
            <div>
              <b-table-simple
                hover
                class="table-sm"
              >
                <thead
                  role="rowgroup"
                  class=""
                >
                  <tr
                    role="row"
                    class=""
                  >
                    <th
                      role="columnheader"
                      scope="col"
                      aria-colindex="1"
                      class=""
                    >
                      <div>{{ $t('Nombre') }}</div>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      aria-colindex="2"
                      class=""
                    >
                      <div>{{ $t('Tipo') }}</div>
                    </th>
                    <th
                      role="columnheader"
                      scope="col"
                      aria-colindex="3"
                      class="text-center w-30"
                    >
                      <div>{{ $t('Acciones') }}</div>
                    </th>
                  </tr>
                </thead>
                <draggable
                  :list="maintenancesDocs"
                  :group="{ name: 'maintenancesDocs', pull: 'clone', put: false }"
                  tag="tbody"
                  :sort="userSuperAdmin"
                  @change="handleChangeOrder4"
                >

                  <b-tr
                    v-for="(item, index) in maintenancesDocs"
                    :key="index"
                  >
                    <b-td>
                      {{ item.description }}
                    </b-td>
                    <b-td>
                      {{ item.type }}
                    </b-td>
                    <b-td class="text-center">
                      <span>
                        <feather-icon
                          icon="DownloadIcon"
                          size="20"
                          class="text-primary"
                          @click="download(item)"
                        />
                        <span
                          v-if="userSuperAdmin ||
                            currentUser.roles[0].name === 'tec_client' ||
                            currentUser.roles[0].name === 'admin'
                          "
                          class="d-inline-block pl-1 text-primary"
                          @click="handleEditIconClick(item)"
                        >
                          <feather-icon
                            icon="EditIcon"
                            size="20"
                          />
                        </span>
                        <span
                          v-if="userSuperAdmin ||
                            currentUser.roles[0].name === 'tec_client' ||
                            currentUser.roles[0].name === 'admin'
                          "
                          class="d-inline-block pl-1 text-danger"
                          @click="handleDeleteIconClick(item)"
                        >
                          <feather-icon
                            icon="TrashIcon"
                            size="20"
                          />
                        </span>
                      </span>
                    </b-td>
                  </b-tr>
                </draggable>
              </b-table-simple>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <DocumentCreationModal
      v-model="modalVisible"
      :parameters="parameters"
      :show-document="showDocument"
      :view="view"
      @document-created="loadDocuments"
    />
    <BaseModal
      v-model="modalEdit"
      :title="$t('Nuevo Adjunto')"
      size="lg"
      :loading="loading"
      static-modal
      @confirm-button-click="handleConfirmButtonClick"
    >
      <validation-observer
        v-if="currentDoc"
        ref="document-form"
      >
        <b-form @submit.prevent>
          <b-row>
            <b-col cols="12">
              <b-form-group
                :label="$t('Documento')"
                label-for="document"
              >
                <ImageDropzone
                  v-if="docReady"
                  ref="myDocs"
                  :files="files"
                  max-files="1"
                />
                <small
                  v-if="dropZoneError"
                  class="text-danger"
                >
                  {{ dropZoneError }}
                </small>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Categoria')"
                label-for="document-categort"
              >
                <v-select
                  v-model="category"
                  label="name"
                  :filterable="false"
                  :searchable="false"
                  :options="listCategories"
                  :placeholder="$t('Categoria')"
                  :disabled="disabledSelectCategories"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Descripcion')"
                label-for="description"
              >
                <b-form-textarea
                  ref="description"
                  v-model="description"
                  :value="description"
                  :placeholder="$t('Descripcion')"
                  rows="4"
                  max-rows="4"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </BaseModal>
  </b-overlay>
</template>

<script>
import FormMixin from '@/mixins/formMixin'

import { mapActions, mapGetters } from 'vuex'
import DocumentCreationModal from '@/components/documents/modal/DocumentCreationModal.vue'
import BaseModal from '@/components/ui/modal/BaseModal.vue'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'
import vSelect from 'vue-select'
import draggable from 'vuedraggable'
import Axios from '@/libs/axios'

export default {
  name: 'DocumentsTable',
  components: {
    DocumentCreationModal,
    BaseModal,
    ImageDropzone,
    vSelect,
    draggable,
  },
  mixins: [FormMixin],
  props: {
    parameters: {
      type: Object,
      default: () => { },
    },
    view: {
      type: String,
    },
    // category_id: {
    //   type: String,
    //   default: () => { },
    // },
  },
  data() {
    return {
      loading: false,
      modalVisible: false,
      modalEdit: false,
      currentDoc: false,
      dropZoneError: null,
      files: [],
      orderBy: null,
      currentPage: 1,
      pageLength: 10000,

      description: '',
      docReady: false,

      currentPage2: 1,
      pageLength2: 10000,

      currentPage3: 1,
      pageLength3: 10000,

      currentPage4: 1,
      pageLength4: 10000,
      showDocument: [],
      pages: ['10', '15', '25'],
      fields: [
        {
          label: this.$t('Nombre'),
          key: 'description',
          thStyle: { width: '60%' },
        },
        {
          label: this.$t('Tipo'),
          key: 'type',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '10%' },
        },
        {
          label: this.$t('Acciones'),
          key: 'actions',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '40%' },
        },
      ],

      comercialData: '',
      techData: '',
      manualData: '',
      maintenanceData: '',
      administrativeData: '',

      showComercialData: false,
      showCom: false,
      showTech: false,
      showMan: false,
      showMain: false,
      showAdmin: false,
    }
  },
  computed: {
    ...mapGetters({
      documents: 'documents/getDocuments',
      comercialDocs: 'documents/getComercialDocs',
      totalDocuments: 'documents/getTotalDocuments',
      totalComercialDocs: 'documents/getTotalComercialDocs',
      techDocs: 'documents/getTechDocs',
      totalTechDocs: 'documents/getTotalTechDocs',
      maintenancesDocs: 'documents/getMaintenancesDocs',
      totalMaintenancesDocs: 'documents/getTotalMaintenancesDocs',
      administrativeDocs: 'documents/getAdministrativeDocs',
      totalAdministrativeDocs: 'documents/getTotalAdministrativeDocs',
      currentUser: 'auth/getUser',
      selectCategories: 'docCategories/getDocCategories',
    }),
    userSuperAdmin() {
      return this.currentUser.roles[0].name === 'super_admin'
    },
    disabledSelectCategories () {
      return ['tec_client', 'admin'].includes(this.currentUser.roles[0].name)
    },
    documentDropZone() {
      return this.$refs["myDocs"];
    },
    listCategories () {
      switch (this.view) {
        case 'projects':
          return this.selectCategories.filter(({ name }) => name != 'Comerciales');
        case 'products':
          return this.selectCategories.filter(({ name }) => !['Administrativos','Mantenimiento'].includes(name));
        default:
          return this.selectCategories || [];
      }
    },
    uploadPermissions() {
      if(this.$route.name === 'viewProduct') {
        return !['user', 'admin'].includes(this.currentUser.roles[0].name)
      }
      
      return true
    }
  },
  async mounted() {
    this.loadDocuments()
  },
  methods: {
    ...mapActions({
      getDocuments: 'documents/getDocuments',
      getComercialDocs: 'documents/getComercialDocs',
      getTechDocs: 'documents/getTechDocs',
      getMaintenancesDocs: 'documents/getMaintenancesDocs',
      getAdministrativeDocs: 'documents/getAdministrativeDocs',
      delete: 'documents/delete',
      edit: 'documents/edit',
      listDocCategories: 'docCategories/listDocCategories',
      reorder: 'documents/setOrder',

    }),
    async validate() {
      return !this.isDropZoneEmpty();
    },
    isDropZoneEmpty() {
      const files = this.documentDropZone.getFiles();
      const validation = !files || files.length === 0;

      this.dropZoneError = validation
        ? this.$t("Debe incluir almenos 1 archivo")
        : null;

      return validation;
    },
    async download(item) {
      this.loading = true
      Axios.get(item.doc, {
        headers: {
          'Content-Type': 'application/octet-stream',
        },
        responseType: 'blob',
      })
        .then(response => {
          const a = document.createElement('a')
          const url = window.URL.createObjectURL(response.data)
          a.href = url
          a.download = item.name
          a.click()
          this.loading = false
        })
        .catch(err => {
          console.log('error', err)
          this.loading = false
        })
    },
    reloadDocuments() {
      this.currentPage = 1
      this.currentPage2 = 1
      this.currentPage3 = 1
      this.currentPage4 = 1
      this.loadDocuments()
    },
    async loadDocuments() {
      this.loading = true
      this.showTech = false
      this.showCom = false
      this.showMan = false
      this.showMain = false
      this.showAdmin = false

      try {
        await this.getComercialDocs({
          per_page: this.pageLength,
          page: this.currentPage,
          category_id: 1,
          orderBy: this.orderBy,
          ...this.parameters,
        })

        await this.getTechDocs({
          per_page: this.pageLength2,
          page: this.currentPage2,
          category_id: 2,
          orderBy: this.orderBy,
          ...this.parameters,
        })

        await this.getDocuments({
          per_page: this.pageLength3,
          page: this.currentPage3,
          category_id: 3,
          orderBy: this.orderBy,
          ...this.parameters,
        })

        await this.getMaintenancesDocs({
          per_page: this.pageLength4,
          page: this.currentPage4,
          category_id: 4,
          orderBy: this.orderBy,
          ...this.parameters,
        })

        await this.getAdministrativeDocs({
          per_page: this.pageLength4,
          page: this.currentPage4,
          category_id: 5,
          orderBy: this.orderBy,
          ...this.parameters,
        })

        this.showTech = this.techDocs.length > 0
        this.showCom = this.comercialDocs.length > 0
        this.showMan = this.documents.length > 0
        this.showMain = this.maintenancesDocs.length > 0
        this.showAdmin = this.administrativeDocs.length > 0

      } finally {
        this.loading = false
      }

      await this.selectCategories.filter(item => {
        if (item.id === 1) {
          this.showComercialData = true
          this.comercialData = item
        }
        if (item.id === 2) {
          this.techData = item
        }
        if (item.id === 3) {
          this.manualData = item
        }
        if (item.id === 4) {
          this.maintenanceData = item
        }
        if (item.id === 5) {
          this.administrativeData = item
        }
      })
    },
    handleChangePage(page) {
      this.currentPage = page
      this.loadDocuments()
    },
    handleChangePage2(page) {
      this.currentPage2 = page
      this.loadDocuments()
    },
    handleChangePage3(page) {
      this.currentPage3 = page
      this.loadDocuments()
    },
    handleChangePage4(page) {
      this.currentPage4 = page
      this.loadDocuments()
    },
    handleSortChange(sortParameters) {
      this.orderBy = {
        column: sortParameters.sortBy,
        type: sortParameters.sortDesc ? 'desc' : 'asc',
      }

      this.reloadDocuments()
    },
    async handleEditIconClick(document) {
      this.docReady = false
      this.modalEdit = false

      this.files = []

      this.currentDoc = document
      this.description = this.currentDoc.description
      this.category = this.currentDoc.category

      const file = {
        id: document.id,
        name: `File ${document.id}`,
        url: document.doc,
        description: document.description,
      }

      await this.files.push(file)
      this.docReady = true
      this.modalEdit = true
    },
    async handleConfirmButtonClick() {
      const valid = await this.validate()

      if (!valid) return

      const data = this.$refs.myDocs.getFiles()

      const formData = new FormData()
      let document = this.currentDoc
      const { id } = this.currentDoc

      if (data.length === 0) {

      } else {
        const file = data[0]

        document = {
          description: this.description,
          doc: file,
          name: file.name,
          type: file.name.split('.').pop(),
        }

        Object.keys(document).forEach(key => formData.append(key, document[key]))
      }

      formData.append('category_id', this.category.id)
      formData.append('description', this.description)

      this.edit({ id, document: formData })
    },
    async handleDeleteIconClick(document) {
      const confirmation = await this.$bvModal.msgBoxConfirm(this.$t('deleteQuestionWithName', { name: document.name }), {
        bodyClass: 'bodyDelete',
        footerClass: 'footerDelete',
        okVariant: 'danger',
        okTitle: this.$t('Eliminar'),
        cancelTitle: this.$t('Cancelar'),
        cancelVariant: 'outline-danger',
        centered: true,
      })
      if (!confirmation) {
        return
      }

      this.loading = true
      try {
        await this.delete(document.id)
        await this.loadDocuments()
      } finally {
        this.loading = false
      }
    },
    async handleChangeOrder() {
      const productsSetAux = []

      this.comercialDocs.forEach((element, index) => {
        productsSetAux[index] = {
          order: index + 1,
          id: element.id,
          type: element.type,
          name: element.name,
        }
      })

      this.reorder(productsSetAux.map(item => item.id))
    },
    async handleChangeOrder2() {
      const productsSetAux = []

      this.techDocs.forEach((element, index) => {
        productsSetAux[index] = {
          order: index + 1,
          id: element.id,
          type: element.type,
          name: element.name,
        }
      })

      this.reorder(productsSetAux.map(item => item.id))
    },
    async handleChangeOrder3() {
      const productsSetAux = []

      this.documents.forEach((element, index) => {
        productsSetAux[index] = {
          order: index + 1,
          id: element.id,
          type: element.type,
          name: element.name,
        }
      })

      this.reorder(productsSetAux.map(item => item.id))
    },
    async handleChangeOrder4() {
      const productsSetAux = []

      this.maintenancesDocs.forEach((element, index) => {
        productsSetAux[index] = {
          order: index + 1,
          id: element.id,
          type: element.type,
          name: element.name,
        }
      })

      this.reorder(productsSetAux.map(item => item.id))
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
</style>
